import React, {Component} from "react"
import BaseLayout from "../components/Layout/BaseLayout";
import {Link} from 'gatsby';
import {DISEASES_URL} from "../constants";


class ErrorPage extends Component {

    constructor(props){

        super(props);

        this.state= {
            show:false
        };

    }

    componentDidMount(){

        this.setState({
            show:true
        });
    }


    render() {

        const {location} = this.props;
        const data = {
            pages: [],
            page: {
                title: "Pagina niet gevonden - 404",
                content: "<h1>404 - Pagina niet gevonden</h1>",
                visible: true,
                route: "/404-niet-gevonden",
                breadcrumbs: [],
                metadata: [],
                header: [{body_classes:'no-submenu'}],
                children: [],
                pages:[]
            }
        };

        return (
            <BaseLayout data={data} location={location} show={this.state.show}>
                <br/>
                <h3>De pagina die u zocht hebben wij helaas niet kunnen vinden</h3>
                <p>Wel kunnen wij u helpen met de onderstaande links om verder te zoeken</p>
                <div className="homePage__searchLinks">
                    <a href={DISEASES_URL} className="diseases"><span /> Alle stapelingziekten A-Z </a>
                    <a href="/lysosomale-stapelingsziekten/symptomen" className="symptoms"><span />Alle symptomen A-Z</a>
                    <a href="/lysosomale-stapelingsziekten/visueel-zoeken" className="visual"><span />Visueel zoeken</a>
                </div>
                <a href="/">Terug naar de startpagina</a>
            </BaseLayout>
        )
    }
}

export default ErrorPage;
